import React from 'react'
import { Link } from "gatsby"
import Img from 'gatsby-image';
import styled from 'styled-components'
import { useWordpressBlogEntries } from "../../hooks/use-wordpress-blog-entries"

const NewsGrid = (props) => {
	const { edges } = useWordpressBlogEntries();

	return (
		<GridContainer>
			<ListContainer>
				{edges.slice(0, props.numberPreviews).map(({ node }) => (
				  <Grid key={node.slug}>
	  	      { node.featured_media && (
	  	      <GridMedia>
	  		      <Img
	  		        src={node.featured_media.localFile.childImageSharp.sizes.src}
	  		        sizes={node.featured_media.localFile.childImageSharp.sizes}
	  		        alt={node.title}
	  		        className="img-fluid"
	  		      />
	  	      </GridMedia>
	  	      )}
				  	<GridContent>
					    <span className="cat">{node.categories && node.categories.map(category => `${category.name}, `)}</span>
					    <h3><Link to={`/noticies/${node.slug}`} dangerouslySetInnerHTML={{ __html: node.title }} /></h3>
					    <Summary dangerouslySetInnerHTML={{ __html: node.excerpt }} />
					  </GridContent>
				  </Grid>
				))}
			</ListContainer>
		</GridContainer>
	)
}

const GridContainer = styled.section`

`

const ListContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`

const Grid = styled.div`
	display: flex; 
  padding: 0.5em;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media all and (min-width: 60em) {
    width: 48%;
  }
  @media (max-width: 540px){
    flex-direction: column;
  }
`
const GridContent = styled.div`
	display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 1em;
  width: 100%;
  span {
    flex: 1 0 auto;
  }
  a {
    text-decoration: none;
    color: #000;
    font-weight: bold;
  }
`

const Summary = styled.p`
	a {
	  display: none;
	}	
`

const GridMedia = styled.div`
	width: 100%;
`

export default NewsGrid;